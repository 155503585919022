<template>
  <div class="page-content">
    <div class="row">
      <div v-for="st in status_list" :key="st.key" class="col-md-2 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-between align-items-baseline">
              <h6 class="card-title mb-0">{{ upperFirst(st.label) }}</h6>
            </div>
            <div class="row">
              <div class="col-12 col-md-12 col-xl-12">
                <h5 class="mb-2">{{ batchList.status_count[st.key] || 0 }}</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-header">
            <div class="d-flex justify-content-between">
              <h5>Batch Name: {{ activeBatch.batch_name }}</h5>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-lg-3">
                <el-select v-model="search.search_by" size="small" class="w-100">
                  <el-option v-for="(item, i) in search_options" :key="i" :value="item.value" :label="item.label" />
                </el-select>
              </div>
              <div class="col-lg-3">
                <el-input size="small" clearable @clear="search.page = 1; getList();" v-model="search.search_keyword" placeholder="Keyword you want to search"></el-input>
              </div>
              <div class="col-lg-2">
                <el-select v-model="search.status_filter" size="small" class="w-100">
                  <el-option v-for="(item, i) in status_list" :key="i" :value="item.value" :label="item.label" />
                </el-select>
              </div>
              <div class="col-lg-3">
                <el-button :loading="loading.table" @click="search.page = 1; getList();" size="small" class="btn-default" icon="el-icon-search">Search</el-button>
                <el-button @click="confirmDownload" type="primary" size="mini" icon="el-icon-download">Download</el-button>
              </div>
            </div>
            <div class="table-responsive">
              <table class="table table-hover">
                <thead>
                  <tr>
                    <th>Template Name</th>
                    <th>Channel</th>
                    <th>Recipient</th>
                    <th>Status</th>
                    <th>Sent At</th>
                    <th>Delivered At</th>
                    <th>Read At</th>
                    <th>Failed At</th>
                    <th>Timestamp</th>
                    <th>Message ID</th>
                    <th>
                      User Response
                       <el-tooltip class="item" effect="dark" content="User response when send a template with Reply Buttons" placement="top">
                         <font-awesome icon="question-circle"/>
                       </el-tooltip>
                    </th>
                  </tr>
                </thead>
                <tbody v-if="batchList.count > 0" v-loading="loading.table">
                  <tr v-for="(item) in batchList.rows" :key="item.id">
                    <td>{{ item.template_name }}</td>
                    <td><i :class="[activeBatch.channel_icon, activeBatch.channel_color]"/> {{activeBatch.channel_name_str}}</td>
                    <td>{{ item.raw_recipient_contact }}</td>
                    <td>
                      <el-popover
                        placement="right"
                        width="400"
                        v-if="item.status == 'failed' && item.failed_error_title"
                        trigger="hover">
                        {{ item.failed_error_title }}
                        <span @click="visible = !visible" slot="reference">
                          <i :class="[item.status_icon, item.status_color]"/> {{ item.status_str }} <font-awesome :icon="['far', 'question-circle']"/>
                        </span>
                      </el-popover>
                      <span v-else>
                        <i :class="[item.status_icon, item.status_color]"/> {{ item.status_str }}
                      </span>
                    </td>
                    <td>{{ item.sent_at_str }}</td>
                    <td>{{ item.delivered_at_str }}</td>
                    <td>{{ item.read_at_str }}</td>
                    <td>{{ item.failed_at_str }}</td>
                    <td>{{ item.created_at_str }}</td>
                    <td>{{ item.msg_id }}</td>
                    <td>
                      <span v-if="!item.user_responses.length">-</span>
                      <span v-else v-for="item1 in item.user_responses" :key="item1.msg_id">
                        <!-- {{ item1.body }} -->
                        <el-tag size="small">{{ item1.body }}</el-tag>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
              <el-empty v-if="batchList.count == 0" description="No data"></el-empty>
            </div>
            <b-pagination v-if="batchList.count > per_page" v-model="currentPage" :total-rows="batchList.count" :per-page="per_page"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { upperFirst, forEach } from 'lodash';
import moment from 'moment';
import humanizeDuration from 'humanize-duration';
import reportApi from '@/api/reports';
import popupErrorMessages from '@/library/popup-error-messages';

export default {
  beforeCreate() {
    this.moment = moment;
    this.humanizeDuration = humanizeDuration;
  },
  name: 'BroadcastReports',
  metaInfo: {
    title: 'Broadcast Reports',
  },
  data() {
    return {
      upperFirst,
      search: {
        page: 1,
        date_range: [
          moment().subtract(7, 'days'),
          moment(),
        ],
        search_by: 'phone_number',
        search_keyword: '',
        status_filter: '',
      },
      date_picker_options: {
        disabledDate: (time) => {
          const today = moment();
          const diff = today.diff(moment(time), 'd');
          if (diff > 90 || diff < 0) {
            return true;
          }
          if (diff === 0) {
            if (today.isSame(moment(time), 'd') === false) {
              return true;
            }
          }
          return false;
        },
      },
      loading: {
        table: false,
      },
      page: 1,
      per_page: 20,
      batchList: {
        count: 0,
        rows: [],
        status_count: {},
      },
      search_options: [
        {
          value: 'phone_number',
          label: 'Phone Number',
        },
        {
          value: 'msg_id',
          label: 'Message ID',
        },
      ],
      status_list: [
        {
          label: 'All',
          key: 'total',
          value: '',
        },
        {
          label: 'Sent',
          value: 'sent',
          key: 'sent',
        },
        {
          label: 'Delivered',
          value: 'delivered',
          key: 'delivered',
        },
        {
          label: 'Read',
          value: 'read',
          key: 'read',
        },
        {
          label: 'Failed',
          value: 'failed',
          key: 'failed',
        },
        {
          label: 'Invalid',
          value: 'invalid',
          key: 'invalid',
        },
      ],
    };
  },
  computed: {
    activeBatch() {
      return this.$store.state.report.activeBatch;
    },
    activeWorkspace() {
      return this.$store.state.workspace.activeWorkspace;
    },
    currentPage: {
      get() {
        return this.search.page;
      },
      set(val) {
        this.search.page = val;
        this.getList();
      },
    },
  },
  async mounted() {
    const loader = this.$loading.show();
    await this.getList();
    loader.hide();
  },
  methods: {
    async getList() {
      this.loading.table = true;
      const response = await reportApi.batchDetail({
        workspace_id: this.activeWorkspace._id,
        batch_id: this.$route.params.id,
        query: this.search,
      });
      await popupErrorMessages(response);
      this.batchList = response.data;
      if (response.data.rows) {
        this.batchList.status_count.total = 0;
        forEach(this.batchList.status_count, (v, k) => {
          if (k !== 'total') this.batchList.status_count.total += v;
        });
        this.batchList.rows = response.data.rows.map((v) => {
          v.status_str = upperFirst(v.status);
          v.status_color = '';
          v.sent_at_str = v.sent_at ? moment(v.sent_at).format('DD MMM YYYY, HH:mm') : '-';
          v.delivered_at_str = v.delivered_at ? moment(v.delivered_at).format('DD MMM YYYY, HH:mm') : '-';
          v.read_at_str = v.read_at ? moment(v.read_at).format('DD MMM YYYY, HH:mm') : '-';
          v.failed_at_str = v.failed_at ? moment(v.failed_at).format('DD MMM YYYY, HH:mm') : '-';
          v.created_at_str = moment(v.created_at).format('DD MMM YYYY, HH:mm');
          if (v.status === 'pending') {
            v.sent_at_str = '-';
          }
          if (v.status === 'sent') {
            v.status_icon = 'mdi mdi-check';
            if (v.sent_at_str === '-') {
              v.sent_at_str = v.created_at_str;
            }
          } else if (['delivered', 'read'].includes(v.status)) {
            v.status_icon = 'mdi mdi-check-all';
            if (v.delivered_at_str === '-') {
              v.delivered_at_str = v.sent_at_str !== '-' ? v.sent_at_str : v.created_at_str;
            }
            if (v.status === 'read') {
              if (v.read_at_str === '-') {
                v.read_at_str = v.delivered_at_str;
              }
              v.status_color = 'text-read';
            }
          } else if (v.status === 'failed' || v.status === 'invalid') {
            v.sent_at_str = '-';
            v.status_icon = 'mdi mdi-close-circle-outline';
            v.status_color = 'text-danger';
          }
          return v;
        });
      }
      this.loading.table = false;
    },
    confirmDownload() {
      this.$confirm(this.$t('report.confirm_download'), this.$t('general.confirmation'), {
        confirmButtonText: this.$t('general.yes'),
        cancelButtonText: this.$t('general.no'),
        type: 'warning',
        center: true,
        /* eslint-disable no-param-reassign */
        beforeClose: async (action, instance, cb) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            this.loading.table = true;
            const response = await reportApi.batchDetailDownload({
              workspace_id: this.activeWorkspace._id,
              batch_id: this.$route.params.id,
              query: this.search,
            }).catch(() => {});
            this.loading.table = false;
            instance.confirmButtonLoading = false;
            await popupErrorMessages(response);
            this.$message({
              title: this.$t('general.success'),
              type: 'success',
              message: this.$t('general.waiting_download'),
              duration: 30 * 1000,
              showClose: true,
            });
          }
          cb();
        },
      });
    },
  },
};
</script>
<style>
.text-read {
  color: #249393;
  font-weight:bold;
}
</style>
